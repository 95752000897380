var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-container',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters},on:{"update:filters":[function($event){_vm.filters=$event},_vm.updateFilters]},scopedSlots:_vm._u([{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filters',{attrs:{"fields":_vm.headers,"busy":busy,"list-filters":_vm.listFilters},on:{"input":_vm.updateFilters},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"flex-nowrap",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"py-0 pr-1 flex-shrink-1",attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":_vm.getEditLink(item)}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('v-col',{staticClass:"py-0 pl-1 hover-unhide",attrs:{"cols":"auto"}},[_c('edit-button',{attrs:{"tooltip":_vm.$t('labels.copy_frontend_url'),"icon":"content_copy","xsmall":""},on:{"execute":function($event){return _vm.copyRestaurantFElink(item)}}})],1)],1)]}},{key:"item.fhrsRating",fn:function(ref){
var item = ref.item;
return [(item.fhrsRating)?_c('div',[_c('div',[_vm._v(_vm._s(item.fhrsRating))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('small',_vm._g({staticClass:"g-73--text"},on),[_vm._v(_vm._s(_vm._f("transformDate")(item.fhrsInspectionDate)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDate")(item.fhrsInspectionDate,'YYYY-MM-DD HH:mm')))])])],1):_c('div',[_vm._v("-")])]}},{key:"item.thirdPartyDifferences",fn:function(ref){
var item = ref.item;
return [(item.thirdPartyDifferences)?_c('div',_vm._l((item.thirdPartyDifferences),function(type,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(_vm.$t(("tp_diff.services." + (type.service))))+": "),_vm._l((type.diffs),function(diff,j){return _c('span',{key:j},[_vm._v(" "+_vm._s(_vm.$t('tp_diff.diff_message', { key: _vm.$t(("tp_diff.keys." + (diff.key))), local: diff.local, remote: diff.remote, }))+" ")])})],2)}),0):_c('div',[_vm._v("-")])]}},{key:"item.isEnabledForRestaurantAdmin",fn:function(ref){
var item = ref.item;
return [(_vm.showAdminCheckboxes)?_c('checkbox-input',{staticClass:"mt-0",attrs:{"value":item.isEnabledForRestaurantAdmin,"disabled":!_vm.isSuperAdmin,"hide-details":""},on:{"input":function($event){item.isEnabledForRestaurantAdmin
                                ? _vm.confirmRestaurantAdminDisable(item)
                                : _vm.confirmRestaurantAdminEnable(item)}}}):_vm._e()]}},{key:"item.isActive",fn:function(ref){
                                var item = ref.item;
return [_c('modal-activate-for-today',{attrs:{"api":_vm.api,"model":item,"disable-toggle":!item.isEnabledForRestaurantAdmin,"trigger-class":"mt-0","checkbox-toggle":"","confirm-activate":""},on:{"saved":function($event){return _vm.$refs.iterator.updateItems(true)}}}),(!item.isActive && item.inactivatedUntil)?_c('span',{staticClass:"text-body-2 mb-0 red--text font-italic text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$t('labels.inactivate_for_today'))+" ")]):_vm._e()]}},{key:"rowActions",fn:function(ref){
                                var item = ref.item;
return [_c('edit-button',{attrs:{"to":_vm.getEditLink(item)}}),_c('edit-button',{attrs:{"tooltip":_vm.$t('labels.delivery_settings'),"icon":"motorcycle","to":_vm.getEditLink(item, 3)}}),_c('edit-button',{attrs:{"tooltip":_vm.$t('labels.menu'),"icon":"restaurant","to":_vm.getEditLink(item, 5)}}),_c('edit-button',{attrs:{"tooltip":_vm.$t('actions.copy'),"color":"brandAlt","icon":"content_copy"},on:{"execute":function($event){return _vm.copyItem(item)}}})]}}],null,true)})]}}],null,true)}),_c('confirm-modal',{attrs:{"value":_vm.showAdminEnableModal,"text":_vm.$t('texts.confirm_restaurant_admin_enable', {
                restaurant: _vm.itemSelectedForAdminChange
                    ? _vm.itemSelectedForAdminChange.name
                    : '',
            }),"processing":_vm.processingAdminChange},on:{"onApprove":_vm.restaurantAdminEnable,"onCancel":_vm.resetAdminCheckboxes}}),_c('confirm-modal',{attrs:{"value":_vm.showAdminDisableModal,"text":_vm.$t('texts.confirm_restaurant_admin_disable', {
                restaurant: _vm.itemSelectedForAdminChange
                    ? _vm.itemSelectedForAdminChange.name
                    : '',
            }),"processing":_vm.processingAdminChange},on:{"onApprove":_vm.restaurantAdminDisable,"onCancel":_vm.resetAdminCheckboxes}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }