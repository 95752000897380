<template>
    <div>
        <v-chip
            v-if="!checkboxToggle"
            :class="triggerClass"
            :color="model.isActive ? 'error' : 'success'"
            :disabled="disableToggle"
            small
            @click="toggleActivate"
        >
            {{
                model.isActive
                    ? $t('actions.inactivate')
                    : $t('actions.activate')
            }}
        </v-chip>
        <v-switch
            v-else
            :input-value="model.isActive"
            :class="triggerClass"
            :disabled="disableToggle"
            :label="label"
            :messages="messages"
            type="checkbox"
            readonly
            hide-details="auto"
            @click="toggleActivate"
        ></v-switch>
        <modal-dialog
            v-model="dialog"
            :title="
                model.isActive
                    ? title || $t('labels.inactivate_for')
                    : $t('texts.are_you_sure')
            "
            cancel-button
            :cancel-text="$t('actions.cancel')"
            :ok-text="
                model.isActive
                    ? approveText || $t('actions.inactivate')
                    : $t('actions.activate')
            "
            :ok-color="model.isActive ? approveColor : 'success'"
            :show-overlay="processing"
            @cancel="onCancel"
            @ok="onApprove"
        >
            <v-container v-if="model.isActive" class="px-0" fluid>
                <div class="radio-group-row py-2">
                    <label class="radio">
                        <span>{{ $t('labels.inactivate_for_today') }}</span>
                        <input
                            v-model="inactivateEntity"
                            type="radio"
                            value="only_today"
                            name="ordercancel"
                        />
                    </label>
                </div>
                <div class="radio-group-row py-2">
                    <label class="radio">
                        <span>{{
                            $t('labels.inactivate_for_indefinitely')
                        }}</span>
                        <input
                            v-model="inactivateEntity"
                            type="radio"
                            value="indefinite_period"
                            name="ordercancel"
                        />
                    </label>
                </div>
            </v-container>
            <span v-else>{{
                $t('texts.confirm_restaurant_activation', {
                    restaurant: model.name,
                })
            }}</span>
        </modal-dialog>
    </div>
</template>

<script>
import RestApiCollection from '@/api/RestApiCollection'
import ModalDialog from '@/components/dialog/ModalDialog'

export default {
    components: { ModalDialog },
    props: {
        api: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: null,
        },
        approveColor: {
            type: String,
            default: 'red',
        },
        approveText: {
            type: String,
            default: null,
        },
        model: {
            type: Object,
            default: () => {
                return {}
            },
        },
        triggerClass: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: undefined,
        },
        messages: {
            type: [String, Array],
            default: () => [],
        },
        processing: Boolean,
        checkboxToggle: Boolean,
        confirmActivate: Boolean,
        disableToggle: Boolean,
        noApiCalls: Boolean,
    },

    data() {
        return {
            dialog: false,
            inactivateEntity: 'only_today',
        }
    },

    methods: {
        toggleActivate(e) {
            if (this.disableToggle) return
            e.stopPropagation()
            e.preventDefault()
            if (this.model.isActive || this.confirmActivate) {
                this.dialog = true
            } else if (!this.noApiCalls) {
                RestApiCollection.get(this.api)
                    .activate(this.model.id)
                    .finally(() => {
                        this.$emit('saved')
                    })
            } else {
                this.$emit('activate')
            }
        },
        onApprove() {
            if (!this.noApiCalls) {
                if (this.model.isActive) {
                    if (this.inactivateEntity === 'only_today') {
                        RestApiCollection.get(this.api)
                            .inactivateForToday(this.model.id)
                            .finally(() => {
                                this.$emit('saved')
                                this.dialog = false
                            })
                    } else {
                        RestApiCollection.get(this.api)
                            .inactivate(this.model.id)
                            .finally(() => {
                                this.$emit('saved')
                                this.dialog = false
                            })
                    }
                } else {
                    RestApiCollection.get(this.api)
                        .activate(this.model.id)
                        .finally(() => {
                            this.$emit('saved')
                            this.dialog = false
                        })
                }
            } else {
                if (
                    this.model.isActive &&
                    this.inactivateEntity === 'only_today'
                ) {
                    this.$emit('inactivate-for-today')
                } else {
                    this.$emit(this.model.isActive ? 'inactivate' : 'activate')
                }
                this.dialog = false
            }
        },
        onCancel() {},
    },
}
</script>
