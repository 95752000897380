<template lang="pug">
    v-btn(
        :loading="busy"
        color="primary"
        @click="exportRestaurants")
        v-icon(left) cloud_download
        | {{ $t('actions.export') }}
</template>

<script>
import ExportRestaurants from '@/services/Restaurants/ExportRestaurants'

export default {
    props: {
        selectedFilters: Object,
        selectedItems: Array,
    },
    data() {
        return {
            busy: false,
        }
    },
    methods: {
        exportRestaurants() {
            this.busy = true
            ExportRestaurants({
                ...{ id: this.selectedItems },
                ...this.selectedFilters,
            }).finally(() => {
                this.busy = false
            })
        },
    },
}
</script>
