import SelectApiClientType from '@/api/SelectApiClientType'

export default {
    computed: {
        listFilters() {
            return [
                {
                    key: 'isActive',
                    label: 'labels.deactivated',
                    value: false,
                    inputType: 'checkbox',
                },
                {
                    key: 'isActive',
                    label: 'labels.activated',
                    value: true,
                    inputType: 'checkbox',
                },
                {
                    key: 'exists',
                    type: 'thirdPartyDifferences',
                    label: 'labels.third_party_differences_filter',
                    value: true,
                    inputType: 'checkbox',
                },
                {
                    inputType: 'location',
                    label: 'labels.delivers_to',
                    key: 'address',
                    other: {
                        key: 'deliveryAddress',
                    },
                    wider: true,
                },
                {
                    key: 'openAt',
                    label: 'labels.open_at',
                    inputType: 'date',
                    wider: true,
                },
                {
                    key: 'city',
                    label: 'labels.city',
                    inputType: 'select',
                    translate: true,
                    entity: SelectApiClientType.CITY,
                },
            ]
        },
    },
}
