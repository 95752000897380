import Config from '@/config.loader'

const FeBaseURL = Config.getConfigValue('VUE_APP_PUBLIC_FE_BASE')

export function copyRestaurantFElink(restaurant) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(
            `${FeBaseURL}/restaurant/${restaurant.slug}`
        )
    }
}

export default {
    FeBaseURL,
    copyRestaurantFElink,
}
