<script>
import AddressMultipleMapInput from '@/components/form/Inputs/google/AddressMultipleMapInput'
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'

const PERPAGE = 100

export default {
    components: { AddressMultipleMapInput },
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
    props: {
        selectedFilters: Object,
    },
    data() {
        return {
            dialog: false,
            markers: [],
        }
    },
    computed: {
        deliveryAddressMarker() {
            return this.selectedFilters?.deliveryAddress?.lat
                ? {
                      lat: parseFloat(this.selectedFilters.deliveryAddress.lat),
                      lng: parseFloat(this.selectedFilters.deliveryAddress.lon),
                      title: this.$t('labels.position_dropoff'),
                      icon: null,
                  }
                : {}
        },
    },
    watch: {
        dialog(isOpen) {
            if (isOpen && !this.markers.length) {
                this.markers.push(this.deliveryAddressMarker)
                if (Object.keys(this.deliveryAddressMarker).length) {
                    this.$nextTick(() => {
                        this.$refs.multiMap.setCenter(
                            this.deliveryAddressMarker
                        )
                    })
                }
                this.loadRestaurants()
            }
        },
        selectedFilters: {
            handler() {
                this.markers = []
            },
            deep: true,
        },
    },
    methods: {
        async loadRestaurants() {
            let page = 1,
                hasNextPage = false

            do {
                const query = {
                    ...this.selectedFilters,
                    ...{
                        page,
                        itemsPerPage: PERPAGE,
                    },
                }

                const res = await RestaurantsApiClient.list(query).then(
                    (res) => {
                        hasNextPage = !!res['hydra:view']?.['hydra:next']
                        return res['hydra:member']
                    }
                )

                if (res && Array.isArray(res))
                    res.forEach((el) => {
                        this.markers.push({
                            icon: 'restaurant',
                            lat: el.latitude,
                            lng: el.longitude,
                            title: el.name,
                        })
                    })

                page++
            } while (hasNextPage)
        },
    },
}
</script>

<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        class="restaurants-map"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
                <v-icon>map</v-icon>
                {{ $t('actions.show_on_map') }}
            </v-btn>
        </template>

        <v-card class="restaurants-map-card">
            <v-toolbar>
                <v-toolbar-title>
                    {{ $t('restaurants.list_page_title') }}
                    <v-chip color="primary">
                        {{ markers.length - 1 }}
                    </v-chip>
                </v-toolbar-title>
                <v-toolbar-items class="align-center px-2">
                    <v-chip-group>
                        <v-chip
                            v-for="(value, key) in selectedFilters"
                            :key="key"
                            small
                            >{{ key | capitalize }}: {{ value }}</v-chip
                        >
                    </v-chip-group>
                </v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
            <address-multiple-map-input
                ref="multiMap"
                :addresses="markers"
            ></address-multiple-map-input>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.restaurants-map-card {
    height: 100%;
}
.input-map-frame {
    height: calc(100% - 64px);
}
</style>
